// PaymentData and CalendarData reflect types on the front end for sharing params (TODO: lets expose this eventually)

// Required fields for the payment API (extends Record for URLSearchParams compatibility)
export interface PaymentData extends Record<string, string> {
  inviteeUuid: string;
  inviteeFullName: string;
  inviteeEmail: string;
}

// Additional data populated for displaying the appointment summary
export interface CalendarData extends PaymentData {
  assignedTo: string;
  eventStartTime: string;
  eventName: string;
}

export enum CalComEventType {
  eventTypeSelected = 'Calendar Event Type Selected',
  bookingSuccessful = 'Calendar Booking Succesful',
  __windowLoadComplete = 'Calendar Loaded'
}

export enum GoogleAnalyticsEventName {
  PRECONSULT_ERROR = 'preconsult_error',
  PRESCREENING_ERROR = 'prescreening_error',
  PRESCREENING_START = 'prescreening_start',
  PRESCREENING_COMPLETE = 'prescreening_complete',
  PRECONSULT_START = 'preconsult_start',
  PRECONSULT_COMPLETE = 'preconsult_complete',
  START_BOOKING = 'ga4_3__start_booking',
  SUBMIT_BOOKING = 'ga4_4__submit_booking',
  EVENT_TYPE_SELECTED = 'invitee_event_type_selected',
  COMPLETE_PRE_CONSULT_SUCCESS = 'complete_pre_consult_success',
  PRESCREENING_AND_BOOKING_COMPLETED = 'prescreening_and_booking_completed',
  EXPERIMENT_IMPRESSION = 'experiment_impression'
}

export enum GoogleAnalyticsEventId {
  PRESCREENING_AND_BOOKING_COMPLETED = '9'
}
/*
 * TODO:
 * pull this type in dynamically via a build script, currently i'm pulling fields from below:
 * https://github.com/calcom/prisma-coldstart/blob/main/prisma/schema.prisma#L305:L348
 *
 * I'm being defensive with optionality, as well as only typing out what's required for the front end.
 */
export interface CalComSplitNameInput {
  firstName: string;
  lastName?: string;
}
export type CalComNameInput = string | CalComSplitNameInput;

export type CalComBookingEvent = {
  booking?: {
    responses?: {
      email?: string;
      name?: CalComNameInput;
      phoneNumber?: string;
    };
    startTime?: string;
    title?: string;
    uid?: string;
    user?: {
      name?: string;
    };
    attendees?: {
      id: number;
      email: string;
      name: string;
      timeZone: string;
      locale: string;
      bookingId: number;
    }[];
  };
};

export enum LaunchDarklyTestGroupType {
  CONTROL = 'control',
  VARIANT = 'variant',
  OFF = 'off'
}

type EmailAndPrivacy = {
  email: string;
  accept_privacy_notice: boolean;
  subscribe_product_services: boolean;
};

export type PreScreeningPayload = {
  medical_condition: 'Yes' | 'No';
  tried_treatment: 'Yes' | 'No';
  treatment: 'Yes' | 'No';
  pregnant: 'Yes' | 'No';
  psychosis: 'Yes' | 'No';
  email_and_privacy: EmailAndPrivacy;
  variant: 'pre-screening';
};

type MedicareInfo = {
  number: string;
  reference_no: string;
  full_name: string;
  expiry_date: string;
};

type Address = {
  street_and_house_no: string;
  suburb: string;
  state: string;
  postcode: string;
};

type EmergencyContact = {
  full_name: string;
  relationship: string;
  phone_no: string;
};

type ConsentForm = {
  first_name: string;
  last_name: string;
  signature: string;
};

export type PreConsultationPayload = {
  email: string;
  variant: 'pre-consultation';
  gender: 'Male' | 'Female' | 'Other';
  aboriginal_or_torres_strait_islander: boolean;
  birthday: string;
  drinks_per_week: number;
  smoking: boolean;
  allergies: boolean;
  list_of_allergies?: string;
  history_of_cardiovascular: boolean;
  tried_canabis_before: boolean;
  list_of_conditions: string[];
  previous_treatments: string;
  medicare: MedicareInfo;
  address: Address;
  emergency_contact: EmergencyContact;
  consent_form: ConsentForm;
};

export type QualityOfLifePayload = {
  variant: 'quality-of-life';
  email: string;
  daily_jobs_ease: number;
  commuting_ease: number;
  mobility_ease: number;
  self_care_ease: number;
  Initimate_relationships: number;
  family_relationships: number;
  community_relationships: number;
  despair: number;
  worry: number;
  sad: number;
  calm_and_tranquil: number;
  agitation: number;
  energy: number;
  control: number;
  coping: number;
  pain_frequency: number;
  pain_level: number;
  pain_interferance: number;
  sight_quality: number;
  hearing_quality: number;
  communication_quality: number;
};

export type FormPayload = PreScreeningPayload | PreConsultationPayload | QualityOfLifePayload;

export const isMobile = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  // Use modern screen orientation API
  const isPortrait = window.screen.orientation?.type.includes('portrait') ?? screenHeight > screenWidth;
  const widthThreshold = isPortrait ? 900 : 1200; // Higher threshold for landscape
  const heightThreshold = isPortrait ? 1200 : 900; // Higher threshold for portrait

  // Check if device meets mobile dimensions for current orientation
  if (screenWidth <= widthThreshold && screenHeight <= heightThreshold) {
    // Touch screen detection as primary method
    if ('maxTouchPoints' in window.navigator) {
      return window.navigator.maxTouchPoints > 0;
    }

    // Modern touch screen detection
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') {
      return !!mQ.matches;
    }

    // Experimental support for mobile detection(currently not supported in Safari and firefox)
    if ((navigator as any).userAgentData?.mobile) {
      return true;
    }

    // Last resort: user agent sniffing
    const userAgent = navigator.userAgent;
    return (
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent)
    );
  }

  return false; // Device is likely a tablet or laptop
};

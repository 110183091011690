import { EligibilityVariantImmediateConsult } from '@/components/experiments/EligibilityVariantImmediateConsult';
import { ContactNurse } from '@/pages/ContactNurse';

type ComponentProps = {
  onNextStep: () => void;
  handleShowCalendar: () => void;
};

export const componentRegistry: Record<string, React.ComponentType<ComponentProps>> = {
  EligibilityVariantImmediateConsult,
  ContactNurse
} as const;

export type ComponentName = keyof typeof componentRegistry;

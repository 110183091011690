import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

import Logger from '@/utils/logger';
import { isMobile } from '@/utils/mobile';

export const FFLoadingStateContext = createContext({
  loading: true
});

export function FFLoadingStateProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(true);
  const ldClient = useLDClient();
  const logger = new Logger('FFLoadingStateProvider');

  useEffect(() => {
    if (ldClient) {
      ldClient.waitUntilReady().then(() => {
        const flags = ldClient.allFlags();

        const userContext = flags.anonymous_users
          ? // This allows us to do percentage rollouts and experiments on un-authenticated pages
            {
              kind: 'user',
              anonymous: true,
              isMobile: isMobile()
            }
          : // We lose the ability to do percentage rollouts and experiments on un-authenticated pages (login,
            // forgot password etc) with this approach, however this saves us from automated tests increasing our MAU.
            // https://docs.launchdarkly.com/home/contexts/anonymous-contexts#using-a-shared-key-between-anonymous-contexts
            {
              kind: 'user',
              anonymous: true,
              key: 'shared-anonymous'
            };

        ldClient
          .identify(userContext)
          .then(() => {
            logger.info('LD client identify success', { ...userContext });
            setLoading(false);
          })
          .catch((error) => {
            logger.error('LD client identify failed', { error });
            setLoading(false);
          });
      });
    }
  }, [ldClient]);

  const value = useMemo(() => ({ loading }), [loading]);

  return (
    <div key="ff-loading-state-context">
      <FFLoadingStateContext.Provider value={value}>{children}</FFLoadingStateContext.Provider>
    </div>
  );
}

import Cal from '@calcom/embed-react';
import { Box, CircularProgress } from '@mui/material';

import settings from '@/pcp/constants/constants';
import { splitCalBookingUrl } from '@/pcp/utils/helpers';

type CalBookingProps = {
  url: string | undefined;
  email?: string;
};

export default function CalBooking({ url, email }: CalBookingProps) {
  const [urlOrigin, urlPath] = splitCalBookingUrl(url);

  if (!url) {
    return (
      <Box height="50svh" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Cal
      data-testid="cal-booking-embed"
      data-cal-path={urlPath}
      calOrigin={urlOrigin}
      calLink={urlPath}
      config={email ? { email } : {}}
      embedJsUrl={settings.calendarEmbedJsUrl}
    />
  );
}

import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import type { ComponentName } from '../../registry';
import { componentRegistry } from '../../registry';
import { VisuallyHiddenText } from '../../styled';
import { SectionWrapper } from '../section/styled';

type ContentItem = {
  type: 'text' | 'component';
  value?: string;
  component?: ComponentName;
};

export type ComponentSectionProps = {
  pageTitle: string;
  title: string;
  content: ContentItem[];
  currentStepIndex: number;
  setActiveStep: (stepId: number) => void;
  handleShowCalendar: () => void;
  prevStepIndexId: string;
  trackPageLoad?: boolean;
};

export const ComponentPage = ({
  pageTitle,
  title,
  content,
  setActiveStep,
  handleShowCalendar,
  ...props
}: ComponentSectionProps) => {
  const onNextStep = () => setActiveStep(props.currentStepIndex + 1);
  return (
    <SectionWrapper>
      <VisuallyHiddenText component="h1">{pageTitle}</VisuallyHiddenText>
      <Typography variant="h6" component="h2" sx={{ mt: 2.5 }}>
        {title}
      </Typography>

      {content.map((item: ContentItem, index) => {
        if (item.type === 'component' && item.component) {
          const DynamicComponent = componentRegistry[item.component];
          return <DynamicComponent key={index} onNextStep={onNextStep} handleShowCalendar={handleShowCalendar} />;
        }
        return <ReactMarkdown key={index}>{item.value || ''}</ReactMarkdown>;
      })}
    </SectionWrapper>
  );
};

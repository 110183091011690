import FormBuilder from '@/components/form-builder/FormBuilder';
import settings from '@/constants/constants';
import { FF_DISABLE_CONSULTATION_BUTTONS, FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import { LaunchDarklyTestGroupType } from '@/constants/types';
import metadata from '@/data/pre-screening.json';
import type { UserContext } from '@/hooks/useFeatureFlagValueForUser';
import { useFeatureFlagValueForUser } from '@/hooks/useFeatureFlagValueForUser';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import SystemUnavailable from '@/pages/SystemUnavailable';
import { restoreObject } from '@/utils/restoreObject';
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Calendar from './Calendar';
import { WithPageWrapper } from './components/Branding';

type Props = {
  urlParams?: string | Record<string, string> | URLSearchParams;
  callback?: (event: string, data: FieldValues) => void;
  onSubmit?: (data: FieldValues, error: unknown) => void;
  pageWrapper?: boolean;
};

export default function PreScreeningForm({ urlParams, callback, onSubmit, pageWrapper = true }: Props) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [searchParams] = useSearchParams();
  const { flags, loading } = useFeatureFlags();

  const paymentFlagUserContext: UserContext = useMemo(
    () => ({
      kind: 'user',
      anonymous: true
    }),
    []
  );

  const { flagValue: paymentFlagValue, loading: paymentFlagLoading } = useFeatureFlagValueForUser<
    'control' | 'variant' | 'off'
  >(FF_PAYMENT_SKIPPED_FLOW_EXPERIMENT, paymentFlagUserContext);

  const devmodeShowCalendar = Boolean(searchParams.get('cal'));
  const showImmediateNurseConsultPage = Boolean(searchParams.get('contactNurse'));

  const promocode = searchParams.get('promocode');
  const preloadCalendarAsHidden = Boolean(emailAddress && promocode);

  const queryParams = new URLSearchParams(urlParams || location.search);
  const { eventType, step, hasSubmittedForm, ...formData } = Object.fromEntries(queryParams);

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };

  useEffect(() => {
    if (emailAddress) {
      localStorage.setItem('prescreeningUserEmail', emailAddress);
    }
  }, [emailAddress]);

  useEffect(() => {
    if (hasSubmittedForm && formData) {
      const restoreData = restoreObject(formData) as FieldValues;
      setEmailAddress(restoreData?.email_and_privacy?.email.toLowerCase() || '');
    }
  }, [hasSubmittedForm, formData]);

  const handleCallback = useCallback(
    (event: string, data: FieldValues) => {
      // Get email address and store it in state to be used in Calendar booking form autofill
      const email = data?.email_and_privacy?.email.toLowerCase();

      if (email) {
        setEmailAddress(email);
      }

      if (callback) {
        callback(event, data);
      }
    },
    [callback]
  );

  if (loading || paymentFlagLoading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const disableConsultations = flags[FF_DISABLE_CONSULTATION_BUTTONS] ?? ffDefaults[FF_DISABLE_CONSULTATION_BUTTONS];

  if (disableConsultations) {
    return <SystemUnavailable />;
  }

  const { postUrl, ...rest } = metadata;

  const getPaymentTestGroup = (): LaunchDarklyTestGroupType => {
    const paymentFlag = paymentFlagValue?.toLowerCase();

    // Todo - also check the origin URL to determine the test group
    if (paymentFlag?.includes('variant')) {
      return LaunchDarklyTestGroupType.VARIANT;
    } else if (paymentFlag?.includes('control')) {
      return LaunchDarklyTestGroupType.CONTROL;
    }
    return LaunchDarklyTestGroupType.OFF;
  };

  const paymentTestGroup = getPaymentTestGroup();
  console.log('PaymentTestGroup', paymentTestGroup);

  return WithPageWrapper(
    <>
      {!showCalendar && !devmodeShowCalendar && !showImmediateNurseConsultPage && (
        <FormBuilder
          {...(rest as any)}
          activeStep={step}
          defaultValues={eventType === 'restore' ? formData : undefined}
          callback={handleCallback}
          postUrl={`${settings.url}${postUrl}`}
          handleShowCalendar={handleShowCalendar}
          hasSubmittedForm={hasSubmittedForm}
          onSubmit={onSubmit}
          emailAddress={emailAddress}
        />
      )}
      {(showCalendar || preloadCalendarAsHidden || devmodeShowCalendar) && (
        <Calendar
          showCalendar={showCalendar || devmodeShowCalendar}
          emailAddress={emailAddress}
          ldarkly_test_group={paymentTestGroup}
        />
      )}
    </>,
    pageWrapper
  );
}

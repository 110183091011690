import type { RumInitConfiguration } from '@datadog/browser-rum';

import type { AppEnv, FormConfig } from './env';
import { getAppEnv, getFormConfig } from './env';

type CommonSettings = Record<string, unknown>;

type DataDogConfig = { env: string } & Pick<
  RumInitConfiguration,
  'sessionSampleRate' | 'sessionReplaySampleRate' | 'allowedTracingUrls'
>;

type EnvSpecificSettings = {
  brazeApiKey: string;
  brazeBaseUrl: string;
  url: string;
  circuitLoginUrl: string;
  calendar: {
    calLink: string;
    calOrigin: string;
    embedJsUrl: string;
  };
  paymentsUrl: string;
  preConsultUrl: string;
  featureFlagSDKKey: string;
  datadog?: DataDogConfig;
  launchDarklyConfig: {
    clientSideID: string;
  };
  googlePlacesApiKey: string;
  qualityOfLifeForm: {
    frontendPath: string;
    backendOrigin: string;
    backendApiPath: string;
  };
};

type GoogleTagManagerSettings = {
  googleTagManagerId: string;
  googleTagManagerAuth: string;
};

type SnowplowSettings = {
  appId: string;
  trackerName: string;
};

type GoogleTagManagerEnvironments = 'staging' | 'prod';

const common: CommonSettings = {};

export type EnvSettings = CommonSettings & EnvSpecificSettings;

const development: EnvSettings = {
  ...common,
  instantConnectPhoneNumber: '+61881337442',
  brazeApiKey: '',
  brazeBaseUrl: '',
  url: 'https://pms.staging.alternaleaf.com.au/api/v1',
  xApiUrl: 'https://pms.staging.alternaleaf.com.au/api',
  circuitLoginUrl: 'https://circuit.staging.montu.com.au/login',
  calendar: {
    calLink: '/team/alternaleaf-nurses/initial-consultation',
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  paymentsUrl: 'https://pms.staging.alternaleaf.com.au/card-detail',
  preConsultUrl: 'https://pre-consult.staging.alternaleaf.com.au',
  featureFlagSDKKey: '19b40cc5-ac82-4051-a4eb-7395df64132d',
  launchDarklyConfig: {
    clientSideID: '64867d6a8ed097125e3ca762'
  },
  googlePlacesApiKey: 'AIzaSyDAdfaMqM0q7jQ6deJ0nsWzEIZB8xfpXpI',
  qualityOfLifeForm: {
    frontendPath: 'quality-of-life',
    backendOrigin: 'https://pms.staging.alternaleaf.com.au',
    backendApiPath: 'api/v1/quality-of-life'
  }
};

const developmentFullStack: EnvSettings = {
  ...development,
  url: 'http://localhost:9000/api/v1',
  qualityOfLifeForm: {
    ...development.qualityOfLifeForm,
    backendOrigin: 'http://localhost:9000'
  }
};

const staging: EnvSettings = {
  ...common,
  instantConnectPhoneNumber: '+61881337442',
  brazeApiKey: 'sdk.iad-02.braze.com',
  brazeBaseUrl: '4258fa44-3379-4de3-89e9-dfffba600dc9',
  url: 'https://pms.staging.alternaleaf.com.au/api/v1',
  xApiUrl: 'https://pms.staging.alternaleaf.com.au/api',
  circuitLoginUrl: 'https://circuit.staging.montu.com.au/login',
  datadog: {
    env: 'staging',
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 0, // percentage of sessions capturing RUM, AND session replays
    allowedTracingUrls: ['https://pms.staging.alternaleaf.com.au/api/v1']
  },
  calendar: {
    calLink: '/team/alternaleaf-nurses/initial-consultation',
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  paymentsUrl: 'https://pms.staging.alternaleaf.com.au/card-detail',
  preConsultUrl: 'https://pre-consult.staging.alternaleaf.com.au',
  featureFlagSDKKey: '482a7d8a-b4ae-46ba-96d3-1f781ff0ba6f',
  launchDarklyConfig: {
    clientSideID: '64867d63ab4da812c04f22c8'
  },
  googlePlacesApiKey: 'AIzaSyDAdfaMqM0q7jQ6deJ0nsWzEIZB8xfpXpI',
  qualityOfLifeForm: {
    frontendPath: 'quality-of-life',
    backendOrigin: 'https://pms.staging.alternaleaf.com.au',
    backendApiPath: 'api/v1/quality-of-life'
  }
};

const production: EnvSettings = {
  ...common,
  instantConnectPhoneNumber: '+61737404815',
  brazeApiKey: '1d23e147-6549-437b-bacd-098aed86bbce',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  url: 'https://pms.alternaleaf.com.au/api/v1',
  xApiUrl: 'https://pms.alternaleaf.com.au/api',
  circuitLoginUrl: 'https://circuit.montu.com.au/login',
  datadog: {
    env: 'production', // was initially prod, updated to be in line with other datadog services
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 50, // percentage of sessions capturing RUM, AND session replays,
    allowedTracingUrls: ['https://pms.alternaleaf.com.au/api/v1']
  },
  calendar: {
    calLink: '/team/alternaleaf-nurses/initial-consultation',
    calOrigin: 'https://bookings.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.alternaleaf.com.au/embed/embed.js'
  },
  paymentsUrl: 'https://pms.alternaleaf.com.au/card-detail',
  preConsultUrl: 'https://pre-consult.alternaleaf.com.au',
  featureFlagSDKKey: '5a1e0251-5c72-4b00-80bd-a0f6e7c75b6b',
  launchDarklyConfig: {
    clientSideID: '64867d5b62b9df124bee5656'
  },
  googlePlacesApiKey: 'AIzaSyBVFk2BtSVOPoORJf3bq7T_JVbfv-z_5zE',
  qualityOfLifeForm: {
    frontendPath: 'quality-of-life',
    backendOrigin: 'https://pms.alternaleaf.com.au',
    backendApiPath: 'api/v1/quality-of-life'
  }
};

const screeningGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-WBQ3Q27',
  googleTagManagerAuth: ''
};

const consultGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-MWGJK4N',
  googleTagManagerAuth: ''
};

const eligibleGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: '',
  googleTagManagerAuth: ''
};

const portalGoogleTagManager: GoogleTagManagerSettings = {
  googleTagManagerId: 'GTM-WZ6PK5RC',
  googleTagManagerAuth: ''
};

const googleTagManager: Record<GoogleTagManagerEnvironments, Record<FormConfig, GoogleTagManagerSettings>> = {
  staging: {
    screening: { ...screeningGoogleTagManager, googleTagManagerAuth: '1otSZZSy8hbJNJ8DPdhT3Q' },
    consult: { ...consultGoogleTagManager, googleTagManagerAuth: 'v9pl99wz7Li-6GECYTVmJg' },
    portal: { ...portalGoogleTagManager, googleTagManagerAuth: 'b483Q-QluKpvgR3KboIkoA' },
    all: { ...portalGoogleTagManager, googleTagManagerAuth: 'b483Q-QluKpvgR3KboIkoA' },
    eligible: { ...eligibleGoogleTagManager }
  },
  prod: {
    screening: { ...screeningGoogleTagManager, googleTagManagerAuth: '' },
    consult: { ...consultGoogleTagManager, googleTagManagerAuth: '' },
    portal: { ...portalGoogleTagManager, googleTagManagerAuth: '' },
    all: { ...portalGoogleTagManager, googleTagManagerAuth: '' },
    eligible: { ...eligibleGoogleTagManager }
  }
};

function getGoogleTagManagerEnvironment(env: AppEnv): GoogleTagManagerEnvironments {
  return env === 'production' ? 'prod' : 'staging';
}

function mergeGoogleTagManagerSettings(settings: EnvSettings, env: AppEnv): EnvSettings {
  const gtmEnv = getGoogleTagManagerEnvironment(env);
  const formConfig = getFormConfig();

  if (formConfig && googleTagManager[gtmEnv][formConfig]) {
    return { ...settings, ...googleTagManager[gtmEnv][formConfig] };
  }

  return settings;
}

export const snowplowSettings: SnowplowSettings = {
  appId: 'montu.forms',
  trackerName: 'hoc.montu-web/apps/forms'
};

function getEnvironmentSettings(): EnvSettings {
  const env: AppEnv = getAppEnv();
  const baseSettings: Record<AppEnv, EnvSettings> = {
    development,
    developmentFullStack,
    staging,
    production
  };

  return mergeGoogleTagManagerSettings(baseSettings[env] || development, env);
}

// PROJECT FEATURE FLAGS - HARNESS
export const FF_CALENDAR_INTEGRATION = 'calendar_integration';

const settings: EnvSettings = getEnvironmentSettings();
export default settings;

import { useContext } from 'react';

import { FF_CALCOM_EMBED_PARAMS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import CalBooking from '@/pcp/components/components/CalBooking';
import Dialog from '@/pcp/components/components/Dialog';
import Step from '@/pcp/components/components/Step';
import { PCPStepContext } from '@/pcp/components/components/StepContextWrapper';
import useTracking from '@/pcp/hooks/useTracking';
import { PCPDataContext } from '@/pcp/pages/PCPortal';
import { areAllFormsComplete, generateCalcomRescheduledByUrlParams } from '@/pcp/utils/helpers';

export default function NurseConsult() {
  const { userData, fetchUserData } = useContext(PCPDataContext);
  const { allSteps, modalOpen, setModalOpen, url, isLink, ga4Events } = useContext(PCPStepContext);
  const allFormsComplete = areAllFormsComplete(allSteps);

  const { flags } = useFeatureFlags();
  const isCalcomRescheduledByEnabled = flags[FF_CALCOM_EMBED_PARAMS];

  const calcomBookingUrl = generateCalcomRescheduledByUrlParams(isCalcomRescheduledByEnabled, url);

  const { sendGa4Events } = useTracking();

  const handleButtonClick = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
    sendGa4Events(ga4Events?.buttonClickEvents);

    if (!isLink) {
      if (calcomBookingUrl && mouseEvent.ctrlKey) {
        window.open(calcomBookingUrl, '_blank');
        return;
      }
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    fetchUserData({ updateLoadingState: false });
  };

  return (
    <>
      <Step onButtonClick={handleButtonClick} divider buttonVariant={allFormsComplete ? 'contained' : 'outlined'} />

      <Dialog open={modalOpen} onCloseButtonClick={handleModalClose}>
        <CalBooking url={calcomBookingUrl} email={userData?.prefillFormData?.email} />
      </Dialog>
    </>
  );
}

import { ButtonWrapper } from '@/components/form-builder/sections/section/styled';
import { FF_IMMEDIATE_NURSE_CONSULT_PERCENTAGE_GATE, FF_IMMEDIATE_NURSE_CONSULTATION } from '@/constants/featureFlags';
import { GoogleAnalyticsEventName } from '@/constants/types';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import AddPromoCode from '@/pages/components/AddPromoCode';
import { formatStringToSha256Hash } from '@/utils/ga-hashing';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Button, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.common.white
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white
  }
}));

const LoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px'
}));

export const EligibilityVariantImmediateConsult = ({
  onNextStep,
  handleShowCalendar
}: {
  onNextStep: () => void;
  handleShowCalendar: () => void;
}) => {
  const { flags, loading } = useFeatureFlags();
  const [immediateConsultationAutoToggle, _] = useState(() => flags[FF_IMMEDIATE_NURSE_CONSULTATION]);
  const showCallButton = flags[FF_IMMEDIATE_NURSE_CONSULT_PERCENTAGE_GATE];
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  const [searchParams] = useSearchParams();
  const promocode = searchParams.get('promocode') || '';

  const userEmail = searchParams.get('email') || localStorage.getItem('prescreeningUserEmail');
  const shaEmail = userEmail ? formatStringToSha256Hash(userEmail) : '';

  const handleAnalyticsOnClick = () => {
    const trackingPayload = {
      event_category: 'cal.com',
      event_label: 'Immediate Nurse Consultation',
      sha_email: shaEmail,
      ...(promocode ? { promocode } : {})
    };
    sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.START_BOOKING, trackingPayload);
  };

  useEffect(() => {
    // GA4 experiment impression
    if (immediateConsultationAutoToggle && !loading) {
      const trackingPayload = {
        experiment_id: FF_IMMEDIATE_NURSE_CONSULTATION,
        variant_id: showCallButton ? 'variant' : 'control',
        experiment_platform: 'LaunchDarkly',
        sha_email: shaEmail,
        ...(promocode ? { promocode } : {})
      };
      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.EXPERIMENT_IMPRESSION, trackingPayload);
    }
  }, [sendGoogleAnalyticsEvent, searchParams, showCallButton, shaEmail]);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  }

  return (
    <Box data-testid="eligibility-variant">
      <Typography variant="h6" component="h2" fontWeight={'bold'} marginBottom={8}>
        {"You're eligible for a complimentary initial assessment"}
      </Typography>
      <Typography marginBottom={5}>Speak to one of our nurses about your medical history and our services</Typography>
      <Typography marginBottom={4}>{"What you'll need:"}</Typography>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <CreditCardIcon />
        <Typography marginLeft={2}>Medicare card number (or IHI)</Typography>
      </Stack>

      <AddPromoCode />

      <ButtonWrapper alignItems={{ md: 'center' }}>
        {immediateConsultationAutoToggle && showCallButton ? (
          <>
            <Button
              type="button"
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                handleAnalyticsOnClick();
                onNextStep();
              }}
            >
              Proceed to consultation
            </Button>
            <Typography textAlign={'center'}>or</Typography>
            <StyledButton
              type="button"
              color="secondary"
              size="large"
              variant="contained"
              onClick={handleShowCalendar}
              startIcon={<CalendarTodayIcon />}
            >
              <Typography>Schedule for later</Typography>
            </StyledButton>
          </>
        ) : (
          <StyledButton
            type="button"
            color="secondary"
            size="large"
            variant="contained"
            onClick={handleShowCalendar}
            startIcon={<CalendarTodayIcon />}
          >
            <Typography>Schedule</Typography>
          </StyledButton>
        )}
      </ButtonWrapper>
    </Box>
  );
};

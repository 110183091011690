import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { SectionStep } from '@/components/form-builder/FormBuilder';
import { FF_ENABLE_DISCOUNT_CODE } from '@/constants/featureFlags';
import { GoogleAnalyticsEventName } from '@/constants/types';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import AddPromoCode from '@/pages/components/AddPromoCode';
import { formatPreConsultDataToSha256HashedValues, formatStringToSha256Hash } from '@/utils/ga-hashing';
import { Button } from '@montugroup/design-system';
import { ChevronLeft } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import ReactMarkdown from '../../components/markdown/ReactMarkdown';
import { VisuallyHiddenText } from '../../styled';
import { ButtonWrapper, SectionWrapper } from './styled';

export type SectionProps = {
  currentStepIndex: number;
  setActiveStep: (stepId: number) => void;
  onNextStep: () => void;
  prevStepIndexId: string;
  trackPageLoad?: boolean;
} & SectionStep;

const Section = ({
  id,
  pageTitle,
  title,
  content,
  prevStepIndexId,
  currentStepIndex,
  setActiveStep,
  onNextStep,
  buttonHref,
  buttonText,
  trackPageLoad = true,
  trackingConfig,
  trackingData
}: SectionProps) => {
  const [searchParams] = useSearchParams();
  const { flags, loading } = useFeatureFlags();
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();
  const ffEnableDiscountCode = flags[FF_ENABLE_DISCOUNT_CODE];
  const isBookAppointment = buttonText === 'Book consultation now';

  const { getValues } = useFormContext();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleBackNavigation = async () => {
    const prevStepArray: number[] = JSON.parse(sessionStorage.getItem(prevStepIndexId) || `[]`);
    const prevStep = prevStepArray.pop();

    sessionStorage.setItem(prevStepIndexId, JSON.stringify(prevStepArray));
    setActiveStep(prevStep ?? --currentStepIndex);
  };

  useEffect(() => {
    if (trackPageLoad) {
      const { event, stepName, ...rest } = trackingData;
      const promocode = searchParams.get('promocode');
      const addPromoCodeToTracking = [
        GoogleAnalyticsEventName.PRESCREENING_COMPLETE as string,
        GoogleAnalyticsEventName.PRECONSULT_COMPLETE as string
      ].includes(event);
      // Incubeta gave feedback to send the last known successful step number
      const previousStepArray = Array.from(JSON.parse(sessionStorage.getItem(prevStepIndexId) || `[0, 1]`));
      const failedStepIndex = Number(previousStepArray.pop()) + 1;
      const stepNumber = Number(trackingConfig?.showPreviousStepId ? failedStepIndex : id);

      // Determine tracking details related to event type
      const isPrescreeningComplete = event === GoogleAnalyticsEventName.PRESCREENING_COMPLETE;
      const isPreconsultComplete = event === GoogleAnalyticsEventName.PRECONSULT_COMPLETE;

      // Get values used in GA SHA-256 tracking values
      const userEmail = getValues('email_and_privacy')?.email;
      const formValues = getValues();

      const trackingPayload = {
        step_number: stepNumber.toString(),
        step_name: stepName,
        ...(promocode && addPromoCodeToTracking ? { promocode } : {}),
        ...rest,
        ...(isPrescreeningComplete && userEmail ? { sha_email: formatStringToSha256Hash(userEmail) } : {}),
        ...(isPreconsultComplete && formValues ? formatPreConsultDataToSha256HashedValues(formValues) : {})
      };

      sendGoogleAnalyticsEvent(event, trackingPayload);
    }
  }, [
    sendGoogleAnalyticsEvent,
    trackingData,
    trackPageLoad,
    searchParams,
    prevStepIndexId,
    trackingConfig?.showPreviousStepId,
    id
  ]);

  return (
    <SectionWrapper>
      <VisuallyHiddenText component="h1">{pageTitle}</VisuallyHiddenText>
      <>
        <Typography variant="h6" component="h2" sx={{ mt: 2.5 }}>
          {title}
        </Typography>

        {isBookAppointment && <CheckCircleIcon color="success" sx={{ height: '1.75rem', width: '1.75rem', mb: -2 }} />}
        {content.map((item, index) => (
          <ReactMarkdown key={index}>{item.value}</ReactMarkdown>
        ))}
        {isBookAppointment && ffEnableDiscountCode && <AddPromoCode />}

        <ButtonWrapper>
          {!loading && (
            <>
              {currentStepIndex > 0 && (
                <Button
                  sx={{
                    width: isDesktop ? 'auto' : '100%',
                    order: isDesktop ? 1 : 2
                  }}
                  size="large"
                  type="button"
                  variant="outlined"
                  onClick={handleBackNavigation}
                >
                  {isDesktop && <ChevronLeft style={{ marginLeft: '-0.4rem' }} />}
                  <Box ml={1}>Back</Box>
                </Button>
              )}
              <Button
                type="button"
                sx={{
                  width: isDesktop ? 'auto' : '100%',
                  order: isDesktop ? 2 : 1
                }}
                size="large"
                variant="contained"
                href={buttonHref}
                onClick={buttonHref ? undefined : onNextStep}
                id={trackingData.clickId}
              >
                {buttonText}
              </Button>
            </>
          )}
        </ButtonWrapper>
      </>
    </SectionWrapper>
  );
};

export default Section;

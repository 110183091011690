import FormBuilder from '@/components/form-builder/FormBuilder';
import settings from '@/constants/constants';
import { FF_DISABLE_CONSULTATION_BUTTONS } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import { LaunchDarklyTestGroupType } from '@/constants/types';
import metadata from '@/data/immediate-consult.json';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import SystemUnavailable from '@/pages/SystemUnavailable';
import { WithPageWrapper } from '@/pages/components/Branding';
import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import Calendar from './Calendar';

type Props = {
  urlParams?: string | Record<string, string> | URLSearchParams;
  _callback?: (event: string, data: FieldValues) => void;
  onSubmit?: (data: FieldValues, error: unknown) => void;
  pageWrapper?: boolean;
};

export default function ConsultEligible({ urlParams, onSubmit }: Props) {
  const [showCalendar, setShowCalendar] = useState(false);
  const { flags, loading } = useFeatureFlags();
  const { postUrl, ...rest } = metadata;

  const queryParams = new URLSearchParams(urlParams || location.search);
  const { step } = Object.fromEntries(queryParams);

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const disableConsultations = flags[FF_DISABLE_CONSULTATION_BUTTONS] ?? ffDefaults[FF_DISABLE_CONSULTATION_BUTTONS];

  if (disableConsultations) {
    return <SystemUnavailable />;
  }

  return WithPageWrapper(
    <>
      {!showCalendar && (
        <FormBuilder
          {...(rest as any)}
          activeStep={step as any}
          postUrl={`${settings.url}${postUrl}`}
          handleShowCalendar={handleShowCalendar}
          onSubmit={onSubmit}
        />
      )}
      {showCalendar && (
        <Calendar
          showCalendar={showCalendar}
          emailAddress={queryParams.get('email') || ''}
          ldarkly_test_group={LaunchDarklyTestGroupType.VARIANT}
        />
      )}
    </>,
    true
  );
}

import { IconButton, Link, Modal, Typography } from '@mui/material';
import { useState } from 'react';

import ReactMarkdown from 'react-markdown';
import type { ModalType } from '../../FormType';
import { StyledModal } from '../../styled';

export default function CustomModal(props: ModalType) {
  const { title, content, secondTitle, secoundContent } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const customLinkComponent = {
    a: ({ href, children }: { href?: string; children: React.ReactNode }) => (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', textDecoration: 'underline' }}
      >
        {children}
      </a>
    )
  };
  return (
    <div>
      <Link component="button" onClick={handleOpen} sx={{ marginY: 3, fontWeight: 'bold' }}>
        {title}
      </Link>
      <Modal open={open} onClose={handleClose}>
        <StyledModal>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 17, top: 11 }}>
            <Typography sx={{ fontSize: '1.3rem', color: 'text.primary', width: '2rem' }}>&#10006;</Typography>
          </IconButton>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </Typography>
          <Typography sx={{ mt: 6 }} variant="h6" component="h2">
            {secondTitle}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <ReactMarkdown components={customLinkComponent}>{secoundContent}</ReactMarkdown>
          </Typography>
        </StyledModal>
      </Modal>
    </div>
  );
}
